<template>
  <v-container class="bg-glass-effect py-0 px-0" fluid>
    <BackArrow class="" v-if="isLoadedBeneficiary" :route="'menu-nick'"/>

    <v-row v-if="!isLoadedBeneficiary" justify="center" style="height: 100%">
      <v-col cols="6" align="center" align-self="center">

      </v-col>
    </v-row>

    <v-row v-else class="px-3 my-10">
      <v-col class="pb-4 pt-4 mx-3" cols="12">
        <h2 class="px-0 mx-0" style="color: #532E88">Carteirinhas</h2>
        <p class="body-1 pt-2" v-if="beneficiaries">Selecione um dos benefícios abaixo.</p>
      </v-col>

      <v-col class="mx-3" v-if="beneficiaries">
        <v-row align="center" justify="start">
          <v-col v-if="hasCardAttributes(beneficiary[0])" class="pa-2" cols="6" v-for="(beneficiary, index) in beneficiaries" :key="index">
            <v-card class="item-card" width="auto" height="190" @click="onClickCard(beneficiary)">
              <v-card-text class="ma-0 pt-10">
                <v-row style="height: 180px">
                  <v-col cols="12">
                    <v-row align="center" justify="center">
                      <v-img class="mt-2 mx-16" max-height="90" max-width="90" :src="'/img/icons/menu-nick/icon-card.svg'"/>
                    </v-row>
                    <v-row align="center" justify="center">
                      <p class="description-menu">{{beneficiary[0].benefitName}}</p>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" v-else>
        <p class="px-3">Nenhuma carteirinha foi encontrada.</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import BackArrow from '@/components/covid/back-arrow/index'
  import { mapMutations } from "vuex";
  import EuProtegidoService from "@/services/euprotegido/EuProtegidoService";
  export default {
    name: "walletPrincipal",
    data: () => ({
      benefitOrderNames: [],
      beneficiaries: null,
      cardTemplateIfNotExist: '<p style=\'text-align:center; color:white\'>Não foi possível carregar os dados da carteirinha</p>',
      isLoadedBeneficiary: false
    }),
    components: {
      BackArrow,
    },
    async created () {
      this._euProtegidoService = new EuProtegidoService();

      this.loading(true);
      await this.loadCompanyBenefitOrder();
      this.loadBeneficiaryCards();
      this.loading(false);
    },
    methods: {
      ...mapMutations(["loading"]),
      async loadCompanyBenefitOrder() {
        await this._euProtegidoService.FindCompanyBenefitOrder()
            .then((response) => {
              if(response && response.data) {
                this.benefitOrderNames = response.data;
              }
            })
            .catch(() => {
              this.loading(false);
            })
            .finally(() => {
              this.showCompanyName = true;
            });
      },
      loadBeneficiaryCards() {
        const beneficiariesGroupBy = this.groupBy('benefitName')
        let sortedBeneficiaries = this.getBeneficiariesByBenefitOrderNames();

        if(sortedBeneficiaries && sortedBeneficiaries.length) {
          sortedBeneficiaries = sortedBeneficiaries.filter(bcp => bcp.cardNumber && bcp.cardStartDate);
        }

        if(sortedBeneficiaries && sortedBeneficiaries.length) {
          this.beneficiaries = beneficiariesGroupBy(sortedBeneficiaries);
        }
        this.isLoadedBeneficiary = true
      },
      getBeneficiariesByBenefitOrderNames() {
        let sortedBeneficiaries = []
        let beneficiariesPlans = this.$auth.user() && this.$auth.user().beneficiaries && this.$auth.user().beneficiaries.length ? this.$auth.user().beneficiaries : this.$auth.user().dependents;
        if(this.benefitOrderNames && this.benefitOrderNames.length && beneficiariesPlans && beneficiariesPlans.length) {
          for(const benefit of this.benefitOrderNames) {
            const beneficiaryPlanFound = beneficiariesPlans.find(beneficiaryPlan => beneficiaryPlan.benefitName === benefit);
            if(beneficiaryPlanFound) {
              sortedBeneficiaries.push(beneficiaryPlanFound);
              const indexOf = beneficiariesPlans.indexOf(beneficiaryPlanFound);
              beneficiariesPlans.splice(indexOf, 1);
            }
          }

          if(beneficiariesPlans.length) {
            sortedBeneficiaries = sortedBeneficiaries.concat(beneficiariesPlans);
          }
        }
        return sortedBeneficiaries;
      },
      groupBy(key) {
        return function group(array) {
          return array.reduce((acc, obj) => {
            const property = obj[key];
            acc[property] = acc[property] || [];
            acc[property].push(obj);
            return acc;
          }, {});
        };
      },
      hasCardAttributes(beneficiaryContractedPlan) {
        return beneficiaryContractedPlan && beneficiaryContractedPlan.cardNumber && beneficiaryContractedPlan.cardStartDate;
      },
      onClickCard(beneficiaryContractedPlans) {
        this.$router.push({ name: 'walletList', params: { beneficiaryContractedPlans } })
      },
      clickCard(card) {
        let cards = []
        if(this.$auth.getBeneficiaryCards()) {
          card.dependents.forEach(c => {
            cards.push(c)
          })
          delete card.dependents
          cards.unshift(card)
        }else{
          cards.push(card)
        }

        this.$router.push({name: 'walletDetailCards', params: {cards: cards, style: this.selectBackgroundCard(card.cardImage)} })
      }
    }
  }
</script>

<style scoped>
/*background effect blur*/
.bg-glass-effect{
  background: rgba(255,255,255,.8) !important;
  mix-blend-mode: normal !important;
  backdrop-filter: blur(100px) !important;
  height: 100vh;
}

.item-card{
  border-radius: 11px;
  background: rgba(255, 255, 255, .5);
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1) !important;
  backdrop-filter: blur(24px) !important;
}

.description-menu {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #532E88;
}
</style>


